import * as React from "react"
import { Box } from "@mui/material";
import { StandardPageContainer } from "../components/reviewskew/standardpage/StandardPageContainer";


const Uninstalled = () => {
  return (
    <StandardPageContainer title="Uninstalled - Review Skew">
      <Box textAlign="center">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdLxUoRhCtehIBb3ONy9FxGDg8-o62A8wvKj3TgQwzjQuZHMg/viewform?embedded=true" width="640" height="711" frameBorder="0" marginHeight={0} marginWidth={0}>Loading…</iframe>
      </Box>
    </StandardPageContainer>
  )
}

export default Uninstalled
